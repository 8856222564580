import loadable from '@loadable/component';

import { RouteMeta } from '~/libs/location/routing';

import * as routeActions from './routeActions';

const Homepage = loadable(() => import('~/corporate/scenes/Homepage'));
const LogIn = loadable(() => import('~/corporate/scenes/LogIn'));
const SignUp = loadable(() => import('~/corporate/scenes/SignUp'));
const BusinessSSOLogin = loadable(
  () => import('~/corporate/scenes/BusinessSSOLogin')
);
const BusinessSSOSignUp = loadable(
  () => import('~/corporate/scenes/BusinessSSOSignUp')
);

/**
 * Routes
 *
 * served by Redux First Router
 * used server and client side to render the current route's scene/component
 *
 * imports and routesMeta are organised alphabetically,
 * routesMap is organised alphabetically by _value_
 *
 */
export const routesMap = {
  [`${routeActions.homepage}`]: '/',
  [`${routeActions.business_sso_login}`]: '/business/sso/login',
  [`${routeActions.business_sso_register}`]: '/business/sso/register',
  [`${routeActions.login}`]: '/login',
  [`${routeActions.register}`]: '/register',
};

export const routesMeta: Record<string, RouteMeta> = {
  [`${routeActions.homepage}`]: {
    scene: Homepage,
    pageName: 'home',
  },
  [`${routeActions.business_sso_login}`]: {
    scene: BusinessSSOLogin,
    pageName: 'business_sso_login',
  },
  [`${routeActions.business_sso_register}`]: {
    scene: BusinessSSOSignUp,
    pageName: 'business_sso_sign_up',
  },
  [`${routeActions.login}`]: {
    scene: LogIn,
    pageName: 'sign_in',
  },
  [`${routeActions.register}`]: {
    scene: SignUp,
    pageName: 'sign_up',
  },
};
